var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('form',{staticClass:"main-wrapper__form"},[_c('CustomTabs',{ref:"validation1",attrs:{"tabs":_vm.customTabs,"dataNeeded":true},on:{"getDataTabs":_vm.getDataTabs}}),[_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === 'Wi-Fi'),expression:"selectedTab === 'Wi-Fi'"}],ref:"validation2",attrs:{"placeholderText":'Network name (SSID)',"defaultErrorText":'Network name (SSID) is required',"formField":'ssid',"formPlace":['network', 'wifi'],"inputName":'ssid',"required":_vm.selectedTab === 'Wi-Fi'},on:{"getData":_vm.getData}}),_c('SearchSelect',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === 'Wi-Fi'),expression:"selectedTab === 'Wi-Fi'"}],ref:"validation3",attrs:{"optionsCount":_vm.authentificationMethods,"search":false,"defaultText":'Authentification',"defaultErrorText":'Authentification is required',"form-place":['network', 'wifi'],"formField":'Authentification',"required":_vm.selectedTab === 'Wi-Fi'},on:{"getData":_vm.getData}}),_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.form.network.wifi.Authentification === 'Basic (WPA2 Personal)' &&
          _vm.selectedTab === 'Wi-Fi'
        ),expression:"\n          form.network.wifi.Authentification === 'Basic (WPA2 Personal)' &&\n          selectedTab === 'Wi-Fi'\n        "}],ref:"validation4",attrs:{"required":_vm.form.network.wifi.Authentification === 'Basic (WPA2 Personal)' &&
          _vm.selectedTab === 'Wi-Fi',"placeholderText":'Password',"defaultErrorText":'Password is required',"formField":'password',"hidden":true,"formPlace":['network', 'wifi'],"inputName":'Password'},on:{"getData":_vm.getData}}),_c('SearchSelect',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.form.network.wifi.Authentification ===
            'Enterprise (WPA2 Enterprise)' && _vm.selectedTab === 'Wi-Fi'
        ),expression:"\n          form.network.wifi.Authentification ===\n            'Enterprise (WPA2 Enterprise)' && selectedTab === 'Wi-Fi'\n        "}],ref:"validation5",attrs:{"required":_vm.form.network.wifi.Authentification ===
            'Enterprise (WPA2 Enterprise)' && _vm.selectedTab === 'Wi-Fi',"optionsCount":_vm.security,"search":false,"defaultText":'Security',"default-value":{ name: 'TLS' },"defaultErrorText":'Security is required',"formField":'mode',"form-place":['network', 'wifi', 'enterprise']},on:{"getData":_vm.getData}}),[_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi'
          ),expression:"\n            form.network.wifi.enterprise.mode == 'TLS' &&\n            form.network.wifi.Authentification ==\n              'Enterprise (WPA2 Enterprise)' &&\n            selectedTab === 'Wi-Fi'\n          "}],ref:"validation6",attrs:{"required":_vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi',"placeholderText":'CA Certificates',"defaultErrorText":'CA certificates is required',"file":true,"form-field":'ca_cert',"formPlace":['network', 'wifi', 'enterprise'],"input-name":'ca certificates'},on:{"getData":_vm.getData}}),_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi'
          ),expression:"\n            form.network.wifi.enterprise.mode == 'TLS' &&\n            form.network.wifi.Authentification ==\n              'Enterprise (WPA2 Enterprise)' &&\n            selectedTab === 'Wi-Fi'\n          "}],ref:"validation7",attrs:{"required":_vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi',"placeholderText":'User Idenity',"defaultErrorText":'User idenity is required',"formField":'identity',"formPlace":['network', 'wifi', 'enterprise'],"input-name":'user identity'},on:{"getData":_vm.getData}}),_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi'
          ),expression:"\n            form.network.wifi.enterprise.mode == 'TLS' &&\n            form.network.wifi.Authentification ==\n              'Enterprise (WPA2 Enterprise)' &&\n            selectedTab === 'Wi-Fi'\n          "}],ref:"validation8",attrs:{"required":_vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi',"placeholderText":'User Private Key',"defaultErrorText":'User private key is required',"file":true,"formField":'private_key',"formPlace":['network', 'wifi', 'enterprise'],"input-name":'private key'},on:{"getData":_vm.getData}}),_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi'
          ),expression:"\n            form.network.wifi.enterprise.mode == 'TLS' &&\n            form.network.wifi.Authentification ==\n              'Enterprise (WPA2 Enterprise)' &&\n            selectedTab === 'Wi-Fi'\n          "}],ref:"validation9",attrs:{"required":_vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi',"placeholderText":'User Private Key Password',"defaultErrorText":'User private key password is required',"formField":'private_key_password',"formPlace":['network', 'wifi', 'enterprise'],"input-name":'private key password'},on:{"getData":_vm.getData}}),_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi'
          ),expression:"\n            form.network.wifi.enterprise.mode == 'TLS' &&\n            form.network.wifi.Authentification ==\n              'Enterprise (WPA2 Enterprise)' &&\n            selectedTab === 'Wi-Fi'\n          "}],ref:"validation10",attrs:{"required":_vm.form.network.wifi.enterprise.mode == 'TLS' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi',"placeholderText":'User Certificate',"defaultErrorText":'User certificate is required',"file":true,"formField":'client_cert',"formPlace":['network', 'wifi', 'enterprise'],"input-name":'client certificate'},on:{"getData":_vm.getData}})],[_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.form.network.wifi.enterprise.mode == 'PEAP' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi'
          ),expression:"\n            form.network.wifi.enterprise.mode == 'PEAP' &&\n            form.network.wifi.Authentification ==\n              'Enterprise (WPA2 Enterprise)' &&\n            selectedTab === 'Wi-Fi'\n          "}],ref:"validation11",attrs:{"required":_vm.form.network.wifi.enterprise.mode == 'PEAP' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi',"placeholderText":'CA Certificates',"defaultErrorText":'CA certificates is required',"file":true,"form-field":'ca_cert',"formPlace":['network', 'wifi', 'enterprise'],"input-name":'ca certificates'},on:{"getData":_vm.getData}}),_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.form.network.wifi.enterprise.mode == 'PEAP' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi'
          ),expression:"\n            form.network.wifi.enterprise.mode == 'PEAP' &&\n            form.network.wifi.Authentification ==\n              'Enterprise (WPA2 Enterprise)' &&\n            selectedTab === 'Wi-Fi'\n          "}],ref:"validation12",attrs:{"required":_vm.form.network.wifi.enterprise.mode == 'PEAP' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi',"placeholderText":'User Idenity',"defaultErrorText":'User idenity is required',"formField":'identity',"formPlace":['network', 'wifi', 'enterprise'],"input-name":'user identity'},on:{"getData":_vm.getData}}),_c('CustomInput',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.form.network.wifi.enterprise.mode == 'PEAP' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi'
          ),expression:"\n            form.network.wifi.enterprise.mode == 'PEAP' &&\n            form.network.wifi.Authentification ==\n              'Enterprise (WPA2 Enterprise)' &&\n            selectedTab === 'Wi-Fi'\n          "}],ref:"validation13",attrs:{"required":_vm.form.network.wifi.enterprise.mode == 'PEAP' &&
            _vm.form.network.wifi.Authentification ==
              'Enterprise (WPA2 Enterprise)' &&
            _vm.selectedTab === 'Wi-Fi',"placeholderText":'User Password',"defaultErrorText":'User password is required',"hidden":true,"formField":'password',"formPlace":['network', 'wifi', 'enterprise'],"input-name":'user password'},on:{"getData":_vm.getData}})]],_c('SearchSelect',{ref:"validation14",attrs:{"optionsCount":_vm.timezone,"search":true,"defaultValue":{ name: _vm.guestedTimezone },"defaultText":'Time zone',"form-field":'timezone',"defaultErrorText":'Time zone is required'},on:{"getData":_vm.getData}}),_c('CustomCheckbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === 'Wi-Fi'),expression:"selectedTab === 'Wi-Fi'"}],ref:"customCheckbox",attrs:{"placeholderText":'Hidden network',"formField":'hidden',"formPlace":['network', 'wifi']},on:{"getData":_vm.getData}}),_c('AdvancedSettings',{ref:"advancedSettings",on:{"sendAdvancedForm":_vm.getAdvancedForm}}),_c('button',{staticClass:"main-btn",attrs:{"type":"button"},on:{"click":_vm.downloadJSON}},[_vm._v(" Generate configuration ")]),_c('div',{staticClass:"preview-btn",on:{"click":_vm.showPreviewConfig}},[_vm._v(" Preview configuration ")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }