var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"inputWrapper",class:[
    'input-wrapper',
    {
      active: _vm.active,
      file: _vm.file,
      deletable: _vm.deletable,
    },
  ]},[_c('label',{ref:"label",staticClass:"main-screen__form-item-label",attrs:{"for":"text-input"}},[_vm._v(_vm._s(_vm.placeholderText))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.name),expression:"name",modifiers:{"trim":true}}],ref:"input",class:[
      'main-screen__form-input',
      'main-screen__form-item-warnings',
      _vm.$v.name.$error && _vm.showError ? 'error' : '',
      _vm.deletable ? 'deletable' : '',
      _vm.file ? 'file' : '',
      _vm.hidden ? 'password' : '',
      !_vm.$v.name.$error && _vm.$v.name.$model ? 'valid' : '',
    ],attrs:{"name":_vm.inputName,"type":_vm.hidden ? 'password' : 'text',"readonly":_vm.file,"required":_vm.required,"ipAddress":_vm.ipAddress},domProps:{"value":(_vm.name)},on:{"click":function($event){_vm.resetValidation()
      _vm.uploadFile()},"focusin":function($event){_vm.active = true},"focusout":_vm.focus,"input":[function($event){if($event.target.composing)return;_vm.name=$event.target.value.trim()},_vm.sendData],"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.deletable)?_c('div',{staticClass:"deletable",on:{"click":_vm.deleteThisInput}},[_c('img',{attrs:{"src":require('@/assets/img/cross.svg'),"alt":"delete"}})]):_vm._e(),(_vm.hidden)?_c('div',{staticClass:"show-password",on:{"click":_vm.showPassword}},[(_vm.showPasswordIcon)?_c('img',{attrs:{"src":require('@/assets/img/passwordHide.svg'),"alt":"show password"}}):(_vm.showError)?_c('img',{attrs:{"src":require('@/assets/img/passwordError.svg'),"alt":"show password"}}):(_vm.active)?_c('img',{attrs:{"src":require('@/assets/img/passwordActive.svg'),"alt":"show password"}}):_c('img',{attrs:{"src":require('@/assets/img/passwordEye.svg'),"alt":"show password"}})]):_vm._e(),(_vm.showError && !_vm.$v.name.minLength && !_vm.phone)?_c('div',{staticClass:"error-message"},[_vm._v(" Name must have at least "+_vm._s(_vm.$v.name.$params.minLength.min)+" letters. ")]):_vm._e(),(_vm.showError && !_vm.$v.name.required && !_vm.phone)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.defaultErrorText)+" ")]):_vm._e(),(_vm.showError && !_vm.$v.name.phoneCheck && _vm.phone)?_c('div',{staticClass:"error-message"},[_vm._v(" Please enter a valid phone number ")]):_vm._e(),(_vm.showError && !_vm.$v.name.ipAddress && _vm.ipAddress)?_c('div',{staticClass:"error-message"},[_vm._v(" Please enter a valid IP address ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }